exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-book-festival-2024-js": () => import("./../../../src/pages/BookFestival2024.js" /* webpackChunkName: "component---src-pages-book-festival-2024-js" */),
  "component---src-pages-books-js": () => import("./../../../src/pages/books.js" /* webpackChunkName: "component---src-pages-books-js" */),
  "component---src-pages-canva-js": () => import("./../../../src/pages/canva.js" /* webpackChunkName: "component---src-pages-canva-js" */),
  "component---src-pages-categories-category-slug-js": () => import("./../../../src/pages/categories/{Category.slug}.js" /* webpackChunkName: "component---src-pages-categories-category-slug-js" */),
  "component---src-pages-eventarchive-js": () => import("./../../../src/pages/eventarchive.js" /* webpackChunkName: "component---src-pages-eventarchive-js" */),
  "component---src-pages-events-airtable-data-slug-js": () => import("./../../../src/pages/events/{Airtable.data__Slug}.js" /* webpackChunkName: "component---src-pages-events-airtable-data-slug-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-freeschool-js": () => import("./../../../src/pages/freeschool.js" /* webpackChunkName: "component---src-pages-freeschool-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mailchimp-js": () => import("./../../../src/pages/mailchimp.js" /* webpackChunkName: "component---src-pages-mailchimp-js" */),
  "component---src-pages-mailchimp-lite-js": () => import("./../../../src/pages/mailchimp-lite.js" /* webpackChunkName: "component---src-pages-mailchimp-lite-js" */),
  "component---src-pages-prismic-page-url-js": () => import("./../../../src/pages/{PrismicPage.url}.js" /* webpackChunkName: "component---src-pages-prismic-page-url-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-title-lists-titlelist-slug-js": () => import("./../../../src/pages/title_lists/{Titlelist.slug}.js" /* webpackChunkName: "component---src-pages-title-lists-titlelist-slug-js" */),
  "component---src-pages-titles-title-slug-js": () => import("./../../../src/pages/titles/{Title.slug}.js" /* webpackChunkName: "component---src-pages-titles-title-slug-js" */),
  "component---src-pages-waverly-book-festival-2023-js": () => import("./../../../src/pages/WaverlyBookFestival2023.js" /* webpackChunkName: "component---src-pages-waverly-book-festival-2023-js" */)
}

